import React from "react";
import _ from "underscore";
import classNames from "classnames";
import PropTypes from "prop-types";
import AtomDek from "components/source/atoms/atom-dek";
import AtomHed from "components/source/atoms/atom-hed";
import { onEnterPress } from "helpers/a11y-helper";
import ActionLogger from "action-logger";
import snakecase from "lodash.snakecase";
import { atomHedAttributesPropType, atomTextAttributesPropType } from "components/propTypes";

const baseClass = "molecule-accordion";

// @TODO Refactor to utilize AtomAccordion to centralize implementations
class MoleculeAccordion extends React.Component {
  static propTypes = {
    cmsUrl: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
      })
    ).isRequired,
    hedText: PropTypes.string,
    mobileHedText: PropTypes.string,
    hedAttributes: atomHedAttributesPropType,
    mobileHedAttributes: atomHedAttributesPropType,
    dekAttributes: atomTextAttributesPropType,
    mobileDekAttributes: atomTextAttributesPropType,
  };

  state = {
    openItem: null,
  };

  toggleItem = (item, key) => {
    if (this.state.openItem === key) {
      this.setState({
        openItem: null,
      });
    } else {
      this.setState({
        openItem: key,
      });

      ActionLogger.logAction({
        object: snakecase(baseClass),
        action: `${this.props.cmsUrl}_click`,
        accordion_title: item.title ? snakecase(item.title) : "no_title",
      });
    }
  };

  renderItems() {
    return _.map(this.props.items, (item, key) => {
      const isCurrentlyOpen = this.state.openItem === key;
      const classes = classNames(`${baseClass}__item`, {
        [`${baseClass}--open`]: isCurrentlyOpen,
      });

      return (
        <div
          role="presentation"
          className={classes}
          key={key}
          onClick={() => this.toggleItem(item, key)}
          onKeyDown={onEnterPress(() => this.toggleItem(item, key))}
          data-test-id={`molecule-accordion-${key}`}>
          <button aria-expanded={isCurrentlyOpen} className={`${baseClass}__toggle`}>
            <span className={`${baseClass}__title`}>{item.title}</span>
            <span
              className={`${baseClass}__icon`}
              aria-label={isCurrentlyOpen ? "close content" : "open content"}></span>
          </button>
          <AtomDek
            customClass={`${baseClass}__text`}
            mobileText={item.mobileText}
            text={item.text}
            dekAttributes={this.props.dekAttributes}
            mobileDekAttributes={this.props.mobileDekAttributes}
          />
        </div>
      );
    });
  }

  renderHed() {
    const { hedAttributes, hedText, mobileHedText, mobileHedAttributes } = this.props;
    if (!hedText) {
      return;
    }

    return (
      <AtomHed
        text={hedText}
        hedAttributes={hedAttributes}
        mobileText={mobileHedText}
        mobileHedAttributes={mobileHedAttributes}
      />
    );
  }

  render() {
    return (
      <>
        {this.renderHed()}
        <div className={baseClass}>{this.renderItems()}</div>
      </>
    );
  }
}

export default MoleculeAccordion;
