import { createAction } from "redux-actions";
import ActionTypes from "./action-types.js";

const actions = {
  set: createAction(ActionTypes.SHOPPABLE_IMAGES),
};

export default actions;

export const { set } = actions;
