import PropTypes from "prop-types";

export const fullBleedFiftyFiftyPropType = PropTypes.arrayOf(
  PropTypes.shape({
    eyebrow: PropTypes.string,
    hedText: PropTypes.string,
    dekText: PropTypes.string,
    ctaText: PropTypes.string,
    ctaButtonHref: PropTypes.string,
    imageHref: PropTypes.string,
    imageURL: PropTypes.string,
    mobileImageURL: PropTypes.string,
    mobileVideoSrcMp4: PropTypes.string,
    mobileVideoSrcMp4Poster: PropTypes.string,
    mobileVideoSrcMp4PosterAspectRatio: PropTypes.string,
    mobileVideoSrcWebm: PropTypes.string,
    mobileVideoSrcWebmPoster: PropTypes.string,
    mobileVideoSrcWebmPosterAspectRatio: PropTypes.string,
    videoSrcMp4: PropTypes.string,
    videoSrcMp4Poster: PropTypes.string,
    videoSrcMp4PosterAspectRatio: PropTypes.string,
    videoSrcWebm: PropTypes.string,
    videoSrcWebmPoster: PropTypes.string,
    videoSrcWebmPosterAspectRatio: PropTypes.string,
  })
);
