import _ from "underscore";
var getSelectedFilters = function (filterData, workingFiltersProp) {
  var copy,
    selectedFilters = _.extend({}, workingFiltersProp);

  if (_.isNull(filterData.name) || _.isUndefined(filterData.name)) {
    return;
  }

  // If this filter doesn't have the "selected" property, it's not a checkbox
  // and therefore can only have a single value instead of an array
  if (!_.has(filterData, "selected")) {
    selectedFilters[filterData.filterGroupKey] = filterData.name;
  } else if (filterData.selected) {
    if (selectedFilters[filterData.filterGroupKey]) {
      copy = selectedFilters[filterData.filterGroupKey].slice();
      if (!_.contains(copy, filterData.name)) {
        copy.push(filterData.name);
      }
      selectedFilters[filterData.filterGroupKey] = copy;
    } else {
      selectedFilters[filterData.filterGroupKey] = [filterData.name];
    }
  } else {
    copy = selectedFilters[filterData.filterGroupKey].slice();
    selectedFilters[filterData.filterGroupKey] = _.without(copy, filterData.name);
  }

  return selectedFilters;
};

export default getSelectedFilters;
