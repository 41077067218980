import PropTypes from "prop-types";

export const shoppablePropType = PropTypes.shape({
  similarStylesLink: PropTypes.string,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      imageURL: PropTypes.string,
      designerName: PropTypes.string,
      styleName: PropTypes.string,
      productID: PropTypes.string,
      link: PropTypes.string,
      imageURLAspectRatio: PropTypes.string,
    })
  ),
});
