import PropTypes from "prop-types";
import { refPropType } from "../../propTypes";
import { useState, useEffect } from "react";
import VideoPlay from "images/video_play.svg";
import VideoPause from "images/video_pause.svg";
import styles from "./atom-video-pause-button.module.scss";

export const AtomVideoPauseButton = ({ autoplayEnabled, onVideoPlayStateChanged, videoRef }) => {
  // Handle a11y requirement that allows user to pause video
  const [videoPaused, setVideoPaused] = useState(!autoplayEnabled);
  const [buttonUnhover, setButtonUnhover] = useState(false);

  useEffect(
    function playOrPauseVideo() {
      if (videoPaused) {
        videoRef?.current?.pause();
      } else {
        videoRef?.current?.play();
      }
      onVideoPlayStateChanged?.(videoPaused);
    },
    [videoPaused, videoRef]
  );

  useEffect(function handlePrefersReducedMotion() {
    // If user prefers reduced motion, auto-pause the video
    if (videoRef?.current && window?.matchMedia?.("(prefers-reduced-motion)").matches) {
      setVideoPaused(true);
    }
  }, []);

  const handleClick = e => {
    //Prevent Pause/Play button from navigating when the video is wrapped in an anchor element
    e.preventDefault();
    setVideoPaused(!videoPaused);
  };

  return (
    <button
      data-test-id="video-pause-button"
      className={styles["atom-video-pause-button"] + " " + (buttonUnhover ? styles["unhover"] : "")}
      onMouseLeave={() => !buttonUnhover && setButtonUnhover(true)}
      onClick={e => handleClick(e)}>
      {videoPaused ? (
        <div>
          <VideoPlay /> <span>Play Video</span>
        </div>
      ) : (
        <div>
          <VideoPause /> <span>Pause Video</span>
        </div>
      )}
    </button>
  );
};

AtomVideoPauseButton.propTypes = {
  autoplayEnabled: PropTypes.bool,
  onVideoPlayStateChanged: PropTypes.func,
  videoRef: refPropType.isRequired,
};

export default AtomVideoPauseButton;
