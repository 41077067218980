import { connect } from "react-redux";
import React, { useRef } from "react";
import PropTypes from "prop-types";
import { AtomVideoPauseButton } from "../../atoms/atom-video-pause-button";
import styles from "./styles.module.scss";
import classNames from "classnames";

export function MoleculeVideo({ autoplay = true, additionalClassname, ...props }) {
  const videoRef = useRef(null);

  const mp4Key = props.isMobileViewport ? "mobileVideoSrcMp4" : "videoSrcMp4";
  const webmKey = props.isMobileViewport ? "mobileVideoSrcWebm" : "videoSrcWebm";
  const srcMp4 = props[mp4Key];
  const srcWebm = props[webmKey];

  // derivative keys based on the presence of a video in the content. These are enforced in RTR Admin/Contentful whenever someone adds a video
  // fall back to the imageSrc if no poster is found
  const posterImageSrc = props[`${mp4Key}Poster`] || props[`${webmKey}Poster`];
  const aspectRatio = props[`${mp4Key}PosterAspectRatio`] || props[`${webmKey}PosterAspectRatio`];
  const classes = classNames(styles["molecule-video"], { [additionalClassname]: additionalClassname });

  return (
    <div className={classes}>
      {/* eslint-disable jsx-a11y/media-has-caption */}
      <video
        // Shenanigans! React has a bug where aspectRatio is not actually respected when specified inline in style attributes:
        // https://github.com/facebook/react/issues/21098
        // although the bug is marked as resolved, it still seems to happen in unit tests (and I did see some weirdness with this in the browser too)
        // hopefully react 18 will fix it?
        style={{
          "--video-aspect-ratio": aspectRatio || "auto",
        }}
        poster={posterImageSrc}
        data-test-id="molecule-video"
        className="home-hero-media"
        preload="auto"
        playsInline={true}
        ref={videoRef}
        autoPlay={autoplay}
        muted={autoplay}
        loop={autoplay}>
        {srcMp4 ? <source src={srcMp4} type="video/mp4" /> : <React.Fragment />}
        {srcWebm ? <source src={srcWebm} type="video/webm" /> : <React.Fragment />}
      </video>
      <AtomVideoPauseButton videoRef={videoRef} autoplayEnabled={autoplay} />
    </div>
  );
}

MoleculeVideo.propTypes = {
  autoplay: PropTypes.bool,
  additionalClassname: PropTypes.string,
  isMobileViewport: PropTypes.bool,
  // string building is used to access props because the prop names are very repetitive
  /* eslint-disable react/no-unused-prop-types */
  mobileVideoSrcMp4: PropTypes.string,
  mobileVideoSrcMp4Poster: PropTypes.string,
  mobileVideoSrcMp4PosterAspectRatio: PropTypes.string,
  mobileVideoSrcWebm: PropTypes.string,
  mobileVideoSrcWebmPoster: PropTypes.string,
  mobileVideoSrcWebmPosterAspectRatio: PropTypes.string,
  videoSrcMp4: PropTypes.string,
  videoSrcMp4Poster: PropTypes.string,
  videoSrcMp4PosterAspectRatio: PropTypes.string,
  videoSrcWebm: PropTypes.string,
  videoSrcWebmPoster: PropTypes.string,
  videoSrcWebmPosterAspectRatio: PropTypes.string,
  /* eslint-enable react/no-unused-prop-types */
};

const mapStateToProps = state => {
  return {
    isMobileViewport: state.browser?.isMobileViewport,
  };
};

export default connect(mapStateToProps)(MoleculeVideo);
