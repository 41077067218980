import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { fullBleedFiftyFiftyPropType } from "../../../propTypes";
import RtrImage from "../rtr-image";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import MoleculeVideo from "components/source/molecules/molecule-video-bucket/molecule-video";

import ShoppableOverlay from "../shoppable-overlay/shoppable-overlay";

const propTypes = {
  listItems: fullBleedFiftyFiftyPropType,
  imageMobileAspectRatio: PropTypes.string,
  imageDesktopAspectRatio: PropTypes.string,
  isMobileViewport: PropTypes.bool,
  componentId: PropTypes.string,
};

const FullBleedFiftyFifty = props => {
  const className = "full-bleed-fifty-fifty";

  const renderTextContent = item => {
    if (!(item.eyebrow || item.hedText || item.dekText || item.ctaButtonHref || item.ctaText)) {
      return null;
    }

    const componentId = props.componentId === "homepage-tiles" ? "homepage-tiles-cta" : "";

    return (
      <div className={`${styles["image-text-content"]}`} data-test-id="full-bleed-fifty-fifty-text-content">
        {item.eyebrow && (
          <div className={`${styles["bucket-eyebrow"]}`} data-test-id="bucket-eyebrow">
            {item.eyebrow}
          </div>
        )}
        {item.hedText && (
          <div className={`${styles["bucket-hedText"]}`} data-test-id="bucket-hedText">
            {item.hedText}
          </div>
        )}
        {item.dekText && (
          <div className={`${styles["bucket-dekText"]}`} data-test-id="bucket-dekText">
            {item.dekText}
          </div>
        )}
        {item.ctaButtonHref && item.ctaText && (
          <a href={item.ctaButtonHref} className={classNames(styles["bucket-cta-button"], styles[componentId])}>
            {item.ctaText}
          </a>
        )}
      </div>
    );
  };

  const renderImage = (item, index) => {
    const componentId = props.componentId === "homepage-tiles" ? "homepage-tiles" : "";
    const imageElement = (
      <RtrImage
        src={props.isMobileViewport ? item.mobileImageURL : item.imageURL}
        alt={item.ctaButtonText || "image"}
        style={{
          "--mobile-aspect-ratio": props.imageMobileAspectRatio || "auto",
          "--desktop-aspect-ratio": props.imageDesktopAspectRatio || "auto",
        }}
      />
    );

    return (
      <div className={classNames(styles["full-bleed-image"], styles[componentId])} key={`image-${index}`}>
        <div className={styles["image-holder"]}>
          {item.imageHref ? (
            <a href={item.imageHref} className={styles["image-link"]}>
              {imageElement}
            </a>
          ) : (
            imageElement
          )}

          {item?.shoppable && <ShoppableOverlay shoppable={item.shoppable} />}
        </div>
        {renderTextContent(item)}
      </div>
    );
  };

  const renderVideo = (item, index) => {
    return (
      <div className={`${styles["full-bleed-image"]}`} key={`video-${index}`}>
        <MoleculeVideo
          mobileVideoSrcMp4={item.mobileVideoSrcMp4}
          mobileVideoSrcMp4Poster={item.mobileVideoSrcMp4Poster}
          mobileVideoSrcMp4PosterAspectRatio={item.mobileVideoSrcMp4PosterAspectRatio}
          mobileVideoSrcWebm={item.mobileVideoSrcWebm}
          mobileVideoSrcWebmPoster={item.mobileVideoSrcWebmPoster}
          mobileVideoSrcWebmPosterAspectRatio={item.mobileVideoSrcWebmPosterAspectRatio}
          videoSrcMp4={item.videoSrcMp4}
          videoSrcMp4Poster={item.videoSrcMp4Poster}
          videoSrcMp4PosterAspectRatio={item.videoSrcMp4PosterAspectRatio}
          videoSrcWebm={item.videoSrcWebm}
          videoSrcWebmPoster={item.videoSrcWebmPoster}
          videoSrcWebmPosterAspectRatio={item.videoSrcWebmPosterAspectRatio}
        />
        {renderTextContent(item)}
      </div>
    );
  };

  const renderListItem = (item, index) => {
    const mp4Key = props.isMobileViewport ? "mobileVideoSrcMp4" : "videoSrcMp4";
    const webmKey = props.isMobileViewport ? "mobileVideoSrcWebm" : "videoSrcWebm";
    const videoSrcMp4 = item[mp4Key];
    const videoSrcWebm = item[webmKey];

    if (videoSrcMp4 || videoSrcWebm) {
      return renderVideo(item, index);
    } else if (item.imageURL || item.mobileImageURL) {
      return renderImage(item, index);
    }
  };

  const renderContent = () => {
    const items = props?.listItems;
    if (items && items.length > 0) {
      return (
        <div className={`${styles["images-container"]}`}>
          {items.map((item, index) => {
            return renderListItem(item, index);
          })}
        </div>
      );
    }
    return null;
  };

  return (
    <div className={`${styles[className]}`} data-testid="full-bleed-image-text-block">
      {renderContent()}
    </div>
  );
};

FullBleedFiftyFifty.propTypes = propTypes;

const mapStateToProps = state => {
  return {
    isMobileViewport: state.browser?.isMobileViewport,
  };
};

export default connect(mapStateToProps)(FullBleedFiftyFifty);
