import { isValid, differenceInWeeks } from "date-fns";
import { parseISOWithoutTime } from "helpers/date-helpers";

export const rewardProgressInWeeks = (startDate = null, endDate = null) => {
  const start = parseISOWithoutTime(startDate);
  const unlocked = parseISOWithoutTime(endDate);

  if (!isValid(start) || !isValid(unlocked) || !startDate || !endDate) {
    return null;
  }

  const current = new Date();
  const earningProgress = differenceInWeeks(current, start);
  const earningProgressAdjusted = earningProgress < 1 ? 1 : earningProgress; // This is being done for visual effect, we don't want the bar to display a few pixels of progress
  const endProgress = differenceInWeeks(unlocked, start);

  return Math.round((earningProgressAdjusted / endProgress) * 100);
};

export const awardWithMatchingProperties = (loyaltyAwards, awardProps) => {
  if (!Array.isArray(loyaltyAwards)) {
    return null;
  }

  return loyaltyAwards.find(award => {
    const hasProperties = Object.entries(awardProps)
      .map(([key, value]) => award?.[key] === value)
      .reduce((result, val) => result && val);

    return hasProperties;
  });
};
