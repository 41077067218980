import { ShoppableImages } from "rtr-constants";

import ShoppableImagesActions from "actions/shoppable-images-actions";

import styles from "./styles.module.scss";
import Bag from "./bag.svg";

import { useDispatch } from "react-redux";
import { displayModal } from "actions/shared-actions";

import { shoppablePropType } from "components/propTypes";

const propTypes = {
  shoppable: shoppablePropType.isRequired,
};

const ShoppableOverlay = ({ shoppable }) => {
  const dispatch = useDispatch();

  const openModal = () => {
    dispatch(ShoppableImagesActions.set(shoppable));
    dispatch(displayModal(ShoppableImages.modalName));
  };

  return (
    <button className={styles["overlay"]} onClick={openModal}>
      <p>Shop the Look</p>
      <div className={styles["products-count"]}>
        <Bag />
        <span>{shoppable.products.length}</span>
      </div>
    </button>
  );
};

ShoppableOverlay.propTypes = propTypes;
export default ShoppableOverlay;
